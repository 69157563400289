'use client'

import React, { useState, useRef, useEffect } from 'react';
import { ChevronDown, ChevronUp, Download, ChevronLeft, ChevronRight, MoreVertical, Edit } from 'lucide-react'
import ProductModal from '../Modal/ProductModal';

const TableProducts = ({ products, onAddProduct }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [sortColumn, setSortColumn] = useState('product_code')
  const [sortDirection, setSortDirection] = useState('asc')
  const [openDropdown, setOpenDropdown] = useState(null)
  const dropdownRefs = useRef({});

  const [selectedProduct, setSelectedProduct] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const itemsPerPage = 10
  const totalPages = Math.ceil(products.length / itemsPerPage)

  const sortedProducts = [...products].sort((a, b) => {
    if (a[sortColumn] < b[sortColumn]) return sortDirection === 'asc' ? -1 : 1
    if (a[sortColumn] > b[sortColumn]) return sortDirection === 'asc' ? 1 : -1
    return 0
  })

  const currentProducts = sortedProducts.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  )


  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
    } else {
      setSortColumn(column)
      setSortDirection('asc')
    }
  }

  const handleDownload = async (imageUrl) => {
    try {
      const filename = imageUrl.split('/').pop().split('?')[0];
      const response = await fetch(imageUrl);
      const blob = await response.blob();

      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = filename;

      link.click();
    } catch (error) {
      console.error('Erro ao baixar a imagem:', error);
    }
  };

 

  const handleSaveProduct = (updatedProduct) => {
    if (updatedProduct.id) {
      // Editando produto existente
      onAddProduct((prevProducts) =>
        prevProducts.map((product) =>
          product.id === updatedProduct.id ? updatedProduct : product
        )
      );
    } else {
      // Adicionando novo produto
      onAddProduct((prevProducts) => [...prevProducts, updatedProduct]);
    }
    setIsModalOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is outside of all dropdown buttons and dropdowns
      const isOutside = Object.values(dropdownRefs.current).every(
        (ref) => ref && !ref.contains(event.target)
      );

      if (isOutside) {
        setOpenDropdown(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = (productCode) => {
    // Close any open dropdown before opening a new one
    setOpenDropdown(prevOpenDropdown => 
      prevOpenDropdown === productCode ? null : productCode
    );
  }

  return (
    <div className="container mx-auto p-0 flex flex-col h-full">
      <div className="flex-grow overflow-auto">
        <table className="w-full caption-bottom text-sm">
          <thead className="[&_tr]:border-b sticky top-0 bg-white z-10">
            <tr className="border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted">
              <th className="h-12 px-4 text-left align-middle font-medium text-muted-foreground [&:has([role=checkbox])]:pr-0 cursor-pointer" onClick={() => handleSort('product_code')}>
                Código
                {sortColumn === 'product_code' && sortDirection === 'asc' ? (
                  <ChevronUp className="inline ml-1" />
                ) : (
                  <ChevronDown className="inline ml-1" />
                )}
              </th>
              <th className="h-12 px-4 text-left align-middle font-medium text-muted-foreground [&:has([role=checkbox])]:pr-0 cursor-pointer" onClick={() => handleSort('name')}>
                Nome
                {sortColumn === 'name' && sortDirection === 'asc' ? (
                  <ChevronUp className="inline ml-1" />
                ) : (
                  <ChevronDown className="inline ml-1" />
                )}
              </th>
              <th className="h-12 px-4 text-left align-middle font-medium text-muted-foreground [&:has([role=checkbox])]:pr-0">Tamanhos</th>
              <th className="h-12 px-4 text-left align-middle font-medium text-muted-foreground [&:has([role=checkbox])]:pr-0">Preço</th>
              <th className="h-12 px-4 text-left align-middle font-medium text-muted-foreground [&:has([role=checkbox])]:pr-0">Foto</th>
              <th className="h-12 px-4 text-left align-middle font-medium text-muted-foreground [&:has([role=checkbox])]:pr-0">Ações</th>
            </tr>
          </thead>
          <tbody className="[&_tr:last-child]:border-0">
            {currentProducts.map((product) => (
              <tr key={product.id} className="border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted">
                <td className="p-4 align-middle [&:has([role=checkbox])]:pr-0">{product.product_code}</td>
                <td className="p-4 align-middle [&:has([role=checkbox])]:pr-0">{product.name}</td>
                <td className="p-4 align-middle [&:has([role=checkbox])]:pr-0">
                  {product.size.split(';').map((size, index) => (
                     <span
                     key={index} // Usando o index como chave para o mapeamento
                     className="inline-flex items-center rounded-full border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80 mr-1 mb-1"
                   >
                     {size.trim()} {/* Usando .trim() para remover espaços extras caso existam */}
                   </span>
                 ))}
                </td>
                <td className="p-4 align-middle [&:has([role=checkbox])]:pr-0">R$ {product.price}</td>
                <td className="p-4 align-middle [&:has([role=checkbox])]:pr-0">
                  <button 
                    className="inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground h-10 w-10"
                    onClick={() => handleDownload(product.photo)}
                  >
                    <Download className="h-4 w-4" />
                    <span className="sr-only">Download foto</span>
                  </button>
                </td>
                <td className="p-4 align-middle [&:has([role=checkbox])]:pr-0 relative">
                  <div 
                    ref={el => dropdownRefs.current[product.id] = el} 
                    className="relative"
                  >
                    <button 
                      className="inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground h-10 w-10"
                      onClick={() => toggleDropdown(product.id)}
                    >
                      <MoreVertical className="h-4 w-4" />
                      <span className="sr-only">Abrir menu de ações</span>
                    </button>
                    {openDropdown === product.id && (
                     <div className="absolute right-24 bottom-0 w-44 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50">
                        <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                          <button
                            className="flex w-full items-center px-4 py-2 text-sm hover:bg-accent hover:text-accent-foreground"
                            onClick={() => { setSelectedProduct(product); setIsModalOpen(true); }}
                          >
                            <Edit className="mr-2 h-4 w-4" />
                            <span>Editar</span>
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {/* Modal para edição do produto */}
        {isModalOpen && (<ProductModal product={selectedProduct} onClose={() => setIsModalOpen(false)} onSave={handleSaveProduct}/>
      )}
      </div>
      <div className="mt-auto flex justify-between items-center p-4 bg-white border-t">
        <div>
          Total de Produtos: {products.length}
        </div>
        <div className="flex items-center space-x-2">
          <button
            className="inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input hover:bg-accent hover:text-accent-foreground h-10 w-10"
            onClick={() => setCurrentPage(currentPage > 1 ? currentPage - 1 : 1)}
            disabled={currentPage === 1}
          >
            <ChevronLeft className="h-4 w-4" />
            <span className="sr-only">Página anterior</span>
          </button>
          <span>{currentPage}/{totalPages}</span>
          <button
            className="inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input hover:bg-accent hover:text-accent-foreground h-10 w-10"
            onClick={() => setCurrentPage(currentPage < totalPages ? currentPage + 1 : totalPages)}
            disabled={currentPage === totalPages}
          >
            <ChevronRight className="h-4 w-4" />
            <span className="sr-only">Próxima página</span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default TableProducts