import React from 'react';

export default function HeaderInvoices({ pageName = "Relatório de Mensagens" }) {

  return (
    <div className="flex  flex-col">
      <header className="sticky top-0 z-10 flex h-16 items-center justify-between border-b bg-background px-4 md:px-6">
        <h1 className="text-lg font-semibold">{pageName}</h1>
      </header>
    </div>
  );
}
