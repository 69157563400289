import { createClient } from '@supabase/supabase-js';

// dotenv.config();

const URL_SUPABASE = 'https://dqmevbpikiahsjsgtnxu.supabase.co'
const KEY_SUPABASE = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImRxbWV2YnBpa2lhaHNqc2d0bnh1Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3Mjg4NzY4MDcsImV4cCI6MjA0NDQ1MjgwN30.WO6qUDbkPDW5INoRJY_bO_iL9Tb-uQFrN0NmCFDJsM0'

// Substitua pelas suas credenciais do Supabase
const supabaseUrl = URL_SUPABASE;
const supabaseKey = KEY_SUPABASE;

export const supabase = createClient(supabaseUrl, supabaseKey);
