import React, { useState, useEffect } from 'react'
import HeaderCampaign from '../components/Header/HeaderCampaign';
import TableCampaign from '../components/Table/TableCampaign';
import SuccessToast from '../components/Toast/SuccessToast'
import axios from 'axios';
import { apiUrl } from '../config';

export default function CampaignPage() {
  const [items, setTableItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showToastSuccess, setShowSuccessToast] = useState(false)
  const [toastMessage, setToastMessage] = useState('')

  const fetchCampaign = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${apiUrl}/campaigns/get_campaigns`);
      setTableItems(response.data);
    } catch (error) {
      console.error('Erro ao buscar produtos:', error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCampaign();
  }, []);

  const addItem = (newItem) => {
    setToastMessage('Campanha criada com sucesso!');
    setShowSuccessToast(true);
    setTableItems((prevItens) => [...prevItens, newItem]);
  };

  const handleAddItem = (newItem) => {
    setTableItems((prevItens) => {
      if (newItem.id) {
        return prevItens.map((item) =>
          item.id === newItem.id ? newItem : item
        );
      } else {
        return [...prevItens, newItem];
      }
    });
  };

      
  return (
    <div className="flex flex-col h-screen">
      <HeaderCampaign onAddCampaign={addItem} />
      <main className="flex-grow overflow-hidden p-4">
        <div className="h-full rounded-lg border border-dashed border-gray-300">
          {isLoading ? <div>Carregando...</div> : <TableCampaign items={items} onAddCampaign={handleAddItem}/>}
        </div>
      </main>
    {showToastSuccess && <SuccessToast message={toastMessage} onClose={() => setShowSuccessToast(false)}/>}
  </div>
  );
}
