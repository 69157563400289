'use client'

import React, { useState, useEffect } from 'react'
import { ChevronUp, ChevronLeft, ChevronRight } from 'lucide-react'
import axios from 'axios';
import { apiUrl } from '../../config';


// Função para gerar nomes de meses em português
const getMonthName = (month) => {
  const monthNames = [
    "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
    "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
  ];
  return monthNames[month];
};

export default function Component() {
 //const [items] = useState(() => generateFakeData())
  const [items, setItems] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [sortColumn, setSortColumn] = useState('mesFaturado')
  const [sortDirection, setSortDirection] = useState('desc')

  const itemsPerPage = 50

  useEffect(() => {
    axios.get(`${apiUrl}/invoices/get_invoices`) 
      .then(response => {
        setItems(response.data);
      })
      .catch(error => {
        console.error('Erro ao buscar os dados:', error);
      });
  }, []);

  

  // Ordenação dos itens
  const sortedItems = [...items].sort((a, b) => {
    if (sortColumn === 'mesFaturado') {
      const [yearA, monthA] = a.mesFaturado.split('-')
      const [yearB, monthB] = b.mesFaturado.split('-')

      if (yearA !== yearB) {
        return sortDirection === 'asc' ? yearA.localeCompare(yearB) : yearB.localeCompare(yearA)
      }

      return sortDirection === 'asc' ? monthA - monthB : monthB - monthA
    } else if (sortColumn === 'totalMensagens') {
      return sortDirection === 'asc' ? a.totalMensagens - b.totalMensagens : b.totalMensagens - a.totalMensagens
    }
    return 0
  })

  const totalPages = Math.ceil(sortedItems.length / itemsPerPage)

  const currentItems = sortedItems.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  )

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
    } else {
      setSortColumn(column)
      setSortDirection('asc')
    }
  }

  return (
    <div className="container mx-auto p-0 flex flex-col h-full">
      <div className="flex-grow overflow-auto">
        <table className="w-full caption-bottom text-sm">
          <thead className="[&_tr]:border-b sticky top-0 bg-white z-10">
            <tr className="border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted">
              <th className="h-12 px-4 text-left align-middle font-medium text-muted-foreground [&:has([role=checkbox])]:pr-0 cursor-pointer" onClick={() => handleSort('mesFaturado')}>
                Mês faturado
                <ChevronUp className={`inline ml-1 h-4 w-4 transition-transform duration-200 ${sortColumn === 'mesFaturado' && sortDirection === 'asc' ? 'transform rotate-0' : 'transform rotate-180'}`} />
              </th>
              <th className="h-12 px-4 text-left align-middle font-medium text-muted-foreground [&:has([role=checkbox])]:pr-0 cursor-pointer" onClick={() => handleSort('totalMensagens')}>
                Total de mensagens
                <ChevronUp className={`inline ml-1 h-4 w-4 transition-transform duration-200 ${sortColumn === 'totalMensagens' && sortDirection === 'asc' ? 'transform rotate-0' : 'transform rotate-180'}`} />
              </th>
            </tr>
          </thead>
          <tbody className="[&_tr:last-child]:border-0">
            {currentItems.map((item) => {
              // Transformando o mesFaturado para o formato Mês/Ano
              const [year, month] = item.mesFaturado.split('-');
              const monthName = getMonthName(parseInt(month) - 1); // Subtrai 1 pois o mês começa em 0
              const mesAnoFormatado = `${monthName}/${year}`;
              
              return (
                <tr key={item.id} className="border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted">
                  {/* Coluna de Mês faturado */}
                  <td className="p-4 align-middle [&:has([role=checkbox])]:pr-0">
                    {mesAnoFormatado.split('/').map((part, index) => (
                      <React.Fragment key={index}>
                        {index === 0 ? (
                          // Mês, sem estilização
                          <span>{part}</span>
                        ) : (
                          // Ano, com estilização
                          <span className="text-gray-400">/{part}</span>
                        )}
                      </React.Fragment>
                    ))}
                  </td>

                  {/* Coluna de Total de mensagens */}
                  <td className="p-4 align-middle [&:has([role=checkbox])]:pr-0">
                    {item.totalMensagens.toLocaleString('pt-BR')}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="mt-auto flex justify-between items-center p-4 bg-white border-t">
        <div>
          Total de Itens: {items.length}
        </div>
        <div className="flex items-center space-x-2">
          <button
            className="inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input hover:bg-accent hover:text-accent-foreground h-10 w-10"
            onClick={() => setCurrentPage(currentPage > 1 ? currentPage - 1 : 1)}
            disabled={currentPage === 1}
          >
            <ChevronLeft className="h-4 w-4" />
            <span className="sr-only">Página anterior</span>
          </button>
          <span>{currentPage}/{totalPages}</span>
          <button
            className="inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input hover:bg-accent hover:text-accent-foreground h-10 w-10"
            onClick={() => setCurrentPage(currentPage < totalPages ? currentPage + 1 : totalPages)}
            disabled={currentPage === totalPages}
          >
            <ChevronRight className="h-4 w-4" />
            <span className="sr-only">Próxima página</span>
          </button>
        </div>
      </div>
    </div>
  )
}