// ProductsPage.jsx

import React from 'react';
import HeaderInvoices from '../components/Header/HeaderInvoices';
import TableInvoices from '../components/Table/TableInvoices';

export default function InvoicesPage() {
  return (
    <div className="flex flex-col h-screen">
        <HeaderInvoices />
        <main className="flex-grow overflow-hidden p-4">
            <div className="h-full rounded-lg border border-dashed border-gray-300">
                <TableInvoices />
            </div>
        </main>
    </div>
  );
}
