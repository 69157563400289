import React, { useState } from 'react';
import { PlusCircle } from 'lucide-react';
import CampaignModal from '../Modal/CampaignModal';

export default function HeaderCampaign({ pageName = "Campanhas", onAddCampaign }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Função para abrir o modal
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Função para fechar o modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="flex  flex-col">
      <header className="sticky top-0 z-10 flex h-16 items-center justify-between border-b bg-background px-4 md:px-6">
        <h1 className="text-lg font-semibold">{pageName}</h1>
        <button  
          onClick={openModal}
          className="inline-flex items-center justify-center rounded-md bg-primary px-4 py-2 text-sm font-medium text-primary-foreground shadow transition-colors hover:bg-primary/90 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50"
        >
          <PlusCircle className="mr-2 h-4 w-4" />
          Nova Campanha
        </button>
      </header>
      
      {/* Condicionalmente renderiza o modal se o estado isModalOpen for true */}
      {isModalOpen && <CampaignModal onClose={closeModal} onAddCampaign={onAddCampaign}/>}
    </div>
  );
}
