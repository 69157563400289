import React, { useState, useRef, useEffect } from 'react';
import { ChevronUp, ChevronLeft, ChevronRight, MoreVertical, Eye } from 'lucide-react';
import axios from 'axios';
import { apiUrl } from '../../config';
import moment from 'moment-timezone';

const Dropdown = ({ isOpen, onClose, onView, campaignId, triggerRef }) => {

  if (!isOpen) return null;

  const handleExportCsvClick = async () => {
    try {
      // Faz a requisição para gerar o CSV
      const response = await axios.get(`${apiUrl}/campaigns/csv/${campaignId}`, {
        responseType: 'blob',
      });

      // Criar um link temporário para o download do arquivo
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `campaign_${campaignId}_products.xlsx`); // Nome do arquivo
      document.body.appendChild(link);
      link.click(); // Dispara o clique no link para download
      document.body.removeChild(link); // Limpa o DOM após o download

      // Fechar o dropdown após a exportação
      onClose();
    } catch (error) {
      console.error('Erro ao exportar os produtos da campanha', error);
    }
  };

  return (
    <div className="absolute right-24 bottom-0 w-44 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50">
      <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
        <button
          onClick={handleExportCsvClick}
          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left flex items-center"
          role="menuitem"
        >
          <Eye className="mr-2 h-4 w-4" />
          Visualizar
        </button>
      </div>
    </div>
  );
};

const TableCampaign = ({ items, onAddCampaign }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState('id');
  const [sortDirection, setSortDirection] = useState('asc');
  const [openDropdown, setOpenDropdown] = useState(null);
  const dropdownRefs = useRef({});

  const itemsPerPage = 10;
  const totalPages = Math.ceil(items.length / itemsPerPage);

  const sortedItems = [...items].sort((a, b) => {
    if (a[sortColumn] < b[sortColumn]) return sortDirection === 'asc' ? -1 : 1;
    if (a[sortColumn] > b[sortColumn]) return sortDirection === 'asc' ? 1 : -1;
    return 0;
  });

  const currentItems = sortedItems.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  const handleView = (id) => {
    console.log(`Visualizando item ${id}`);
    setOpenDropdown(null);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is outside of all dropdown buttons and dropdowns
      const isOutside = Object.values(dropdownRefs.current).every(
        (ref) => ref && !ref.contains(event.target)
      );

      if (isOutside) {
        setOpenDropdown(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = (itemId) => {
    // Close any open dropdown before opening a new one
    setOpenDropdown(prevOpenDropdown => 
      prevOpenDropdown === itemId ? null : itemId
    );
  }


  const DateTimeComponent = ({ date }) => {
    const formattedDate = moment(date).tz('America/Sao_Paulo').format('DD-MM-YYYY HH:mm');

    return <span>{formattedDate}</span>;
  };

  return (
    <div className="container mx-auto p-0 flex flex-col h-full">
      <div className="flex-grow overflow-auto">
        <table className="w-full caption-bottom text-sm">
          <thead className="[&_tr]:border-b sticky top-0 bg-white z-10">
            <tr className="border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted">
              <th className="h-12 px-4 text-left align-middle font-medium text-muted-foreground [&:has([role=checkbox])]:pr-0 cursor-pointer" onClick={() => handleSort('name')}>
                Nome
                <ChevronUp className={`inline ml-1 h-4 w-4 transition-transform duration-200 ${sortColumn === 'name' && sortDirection === 'asc' ? 'transform rotate-0' : 'transform rotate-180'}`} />
              </th>
              <th className="h-12 px-4 text-left align-middle font-medium text-muted-foreground [&:has([role=checkbox])]:pr-0">Descrição</th>
              <th className="h-12 px-4 text-left align-middle font-medium text-muted-foreground [&:has([role=checkbox])]:pr-0 cursor-pointer" onClick={() => handleSort('starts_at')}>
                Início
                <ChevronUp className={`inline ml-1 h-4 w-4 transition-transform duration-200 ${sortColumn === 'starts_at' && sortDirection === 'asc' ? 'transform rotate-0' : 'transform rotate-180'}`} />
              </th>
              <th className="h-12 px-4 text-left align-middle font-medium text-muted-foreground [&:has([role=checkbox])]:pr-0 cursor-pointer" onClick={() => handleSort('ends_at')}>
                Término
                <ChevronUp className={`inline ml-1 h-4 w-4 transition-transform duration-200 ${sortColumn === 'ends_at' && sortDirection === 'asc' ? 'transform rotate-0' : 'transform rotate-180'}`} />
              </th>
              <th className="h-12 px-4 text-left align-middle font-medium text-muted-foreground [&:has([role=checkbox])]:pr-0">Status</th>
              <th className="h-12 px-4 text-left align-middle font-medium text-muted-foreground [&:has([role=checkbox])]:pr-0">Ações</th>
            </tr>
          </thead>
          <tbody className="[&_tr:last-child]:border-0">
            {currentItems.map((item) => (
              <tr key={item.id} className="border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted">
                <td className="p-4 align-middle [&:has([role=checkbox])]:pr-0">{item.name}</td>
                <td className="p-4 align-middle [&:has([role=checkbox])]:pr-0">{item.description || '-'}</td>
                <td className="p-4 align-middle [&:has([role=checkbox])]:pr-0"><DateTimeComponent date={item.starts_at} /></td>
                <td className="p-4 align-middle [&:has([role=checkbox])]:pr-0"><DateTimeComponent date={item.ends_at} /></td>
                <td className="p-4 align-middle [&:has([role=checkbox])]:pr-0">
                  <span className={`px-2 py-1 rounded-full text-xs font-semibold
                    ${item.status === 'pending' ? 'bg-gray-200 text-gray-800' : 
                    item.status === 'active' ? 'bg-green-200 text-green-800' :
                    item.status === 'finished' ? 'bg-blue-200 text-blue-800' : ''}`}>
                    {item.status === 'pending' ? 'Pendente' :
                    item.status === 'active' ? 'Ativa' :
                    item.status === 'finished' ? 'Finalizada' : ''}
                  </span>
                </td>
                <td className="p-4 align-middle [&:has([role=checkbox])]:pr-0 relative">
                  <div 
                    ref={el => dropdownRefs.current[item.id] = el} 
                    className="relative"
                  >
                    <button 
                      className="inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground h-10 w-10"
                      onClick={() => toggleDropdown(item.id)}
                    >
                      <MoreVertical className="h-4 w-4" />
                      <span className="sr-only">Ações</span>
                    </button>
                    {openDropdown === item.id && (
                      <div className="absolute right-0 top-full mt-1 z-50">
                        <Dropdown
                          isOpen={true}
                          onClose={() => setOpenDropdown(null)}
                          onView={() => handleView(item.id)}
                          campaignId={item.id}
                        />
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="mt-auto flex justify-between items-center p-4 bg-white border-t">
        <div>
          Total de Itens: {items.length}
        </div>
        <div className="flex items-center space-x-2">
          <button
            className="inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input hover:bg-accent hover:text-accent-foreground h-10 w-10"
            onClick={() => setCurrentPage(currentPage > 1 ? currentPage - 1 : 1)}
            disabled={currentPage === 1}
          >
            <ChevronLeft className="h-4 w-4" />
            <span className="sr-only">Página anterior</span>
          </button>
          <span>{currentPage}/{totalPages}</span>
          <button
            className="inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input hover:bg-accent hover:text-accent-foreground h-10 w-10"
            onClick={() => setCurrentPage(currentPage < totalPages ? currentPage + 1 : totalPages)}
            disabled={currentPage === totalPages}
          >
            <ChevronRight className="h-4 w-4" />
            <span className="sr-only">Próxima página</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default TableCampaign;
