const config = {
    development: {
      API_URL: "http://localhost:5000",
    },
    production: {
      API_URL: "https://prod.welpie.deepfire.ai/",
    },
  };
  
  
  export const apiUrl = config['production'].API_URL;