import React, { useState } from 'react';
import { supabase } from '../../supabaseClient';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { ChevronLeft, ChevronRight, LogOut, Megaphone, Package, Briefcase } from 'lucide-react';

const SidebarItem = ({ icon, text, isCollapsed, to }) => (
  <li className="flex items-center p-2 text-gray-700 hover:bg-gray-100 rounded-lg cursor-pointer">
    <Link to={to} className="flex items-center w-full">
      {icon}
      {!isCollapsed && <span className="ml-3">{text}</span>}
    </Link>
  </li>
);

export default function Sidebar({ companyName = "Welpie", userName = "Welpie" }) {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleSidebar = () => setIsCollapsed(!isCollapsed);
  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  const navigate = useNavigate();

  const userInitials = (() => {
    const names = userName.split(' ');
    if (names.length === 1) {
      return names[0].slice(0, 2).toUpperCase();
    } else {
      return (names[0][0] + names[1][0]).toUpperCase();
    }
  })();

  const handleLogout = async () => {
    try {
      await supabase.auth.signOut();  // Chama o método de logout do supabase
      navigate('/');  // Redireciona o usuário para a página inicial ou login após o logout
    } catch (error) {
      console.error('Erro ao sair:', error.message);
    }
  };

  return (
    <div className={`flex flex-col h-screen bg-white border-r transition-all duration-300 ${isCollapsed ? 'w-16' : 'w-64'}`}>
      <div className="flex-shrink-0 flex items-center justify-between p-4 h-16 border-b">
        {!isCollapsed && (
          <div className="truncate">
            <h2 className="text-lg font-semibold truncate">{companyName}</h2>
            {/* <p className="text-sm text-gray-500 truncate">{userName}</p> */}
          </div>
        )}
        <button onClick={toggleSidebar} className="flex-shrink-0 p-1 rounded-full hover:bg-gray-200">
          {isCollapsed ? <ChevronRight size={24} /> : <ChevronLeft size={24} />}
        </button>
      </div>

      <nav className="flex-grow overflow-y-auto">
        <ul className="space-y-2 p-4">
          <SidebarItem icon={<Megaphone size={24} />} text="Campanhas" isCollapsed={isCollapsed} to="/campaigns" />
          <SidebarItem icon={<Package size={24} />} text="Produtos" isCollapsed={isCollapsed} to="/products" />
          <SidebarItem icon={<Briefcase size={24} />} text="Negócio" isCollapsed={isCollapsed} to="/invoices" />
        </ul>
      </nav>

      <div className="flex-shrink-0 relative p-4 border-t">
        <button
          onClick={toggleDropdown}
          className="flex items-center justify-center w-10 h-10 rounded-full bg-gray-200 text-gray-700 hover:bg-gray-300 focus:outline-none"
        >
          {userInitials}
        </button>
        {isDropdownOpen && (
          <div className="absolute bottom-full left-0 mb-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
            <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
              <button
                onClick={handleLogout}
                className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                role="menuitem"
              >
                <LogOut size={16} className="mr-3" /> Sair
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
